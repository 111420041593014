import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import OrderInfo from '../components/order';

formCreate.component('OrderInfo', OrderInfo);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'directly_order_receive_form',
      formParentCode: 'CRM20211220000000002',
    };
  },

  methods: {

    async formComplete() {
      if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
        const data = await request.get('/sfa/sfaWorkDailyController/orderPaymentQuery', { id: this.formConfig.row.id }).then((res) => (res.success ? res.result : []));
        const sfaVisitStepOrderItems = this.getRule('sfaVisitStepOrderItems');
        sfaVisitStepOrderItems.props = {
          code: this.formConfig.code,
          ...sfaVisitStepOrderItems.props,
          value: data.sfaVisitStepOrderItems,
          detailData: data,
        };
        this.setValue(data);
      }
    },
  },
};
