<template>

  <div class="custom-table">
    <vxe-table border show-overflow ref="xTable" :data="data">
      <vxe-table-column field="productCode" align="center" title="产品编码"></vxe-table-column>
      <vxe-table-column field="productName" align="center" title="产品名称"></vxe-table-column>
      <vxe-table-column field="price" align="center" title="直营产品单价(瓶/盒)元"></vxe-table-column>
      <vxe-table-column field="ext1" align="center" title="规格"></vxe-table-column>
      <vxe-table-column field="quantity" align="center" title="数量（瓶/盒）"></vxe-table-column>
       <vxe-table-column field="amount" align="center" title="总金额"></vxe-table-column>
    </vxe-table>
    <div>
      <div style="font-size: 16px;height: 20px;line-height: 20px;font-weight: 600;margin:20px 0;">订单信息</div>
      <el-form ref="detail" :model="detail" label-width="120px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="订单编码">
              <el-input v-model="detail.orderCode" disabled placeholder="订单编码"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单创建时间">
              <el-input v-model="detail.createDateAll" disabled placeholder="订单创建时间"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建人">
              <el-input v-model="detail.createName" disabled placeholder="创建人"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="创建人岗位">
              <el-input v-model="detail.posName" disabled placeholder="创建人岗位"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属组织">
              <el-input v-model="detail.orgName" disabled placeholder="所属组织"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="订单状态">
              <el-input v-if="detail.enableStatus==='009'" value='正常' disabled placeholder="订单状态"></el-input>
              <el-input v-else-if="detail.enableStatus==='003'" value='关闭' disabled placeholder="订单状态"></el-input>
              <el-input v-else disabled placeholder="订单状态"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item>
          <el-button v-if="config.flag!=='audit' && detail.ext1=='0' &&detail.orderTypeName !=='退货订单' " type="primary" @click="handleClick">确认出库</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>

</template>

<script>
import request from '@/found/utils/request';
import _ from 'xe-utils';

export default {
  props: {
    data: Array,
    detailData: {
      type: Object,
      default: () => ({}),
    },
    formConfig: {
      type: Object,
      default: () => ({}),
    },
  },
  components: { },
  data() {
    return {
      tableData: [],
      detail: {},
      config: {},
    };
  },
  watch: {
    data(newVal, oldVal) {
      for (const item of newVal) {
        item.amount = _.multiply(Number(item.quantity || 0), Number(item.price || 0)).toFixed(2);
      }
      this.tableData = newVal;
    },
    detailData(newVal, oldVal) {
      this.detail = newVal;
    },
    formConfig(newVal, oldVal) {
      this.config = newVal;
    },
  },
  created() {

  },
  methods: {
    // 确认已收货
    handleClick() {
      this.$confirm('是否确定出库?', '温馨提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        request.post('/sfa/sfaWorkDailyController/comfirmWorkOrder', { id: this.detail.id }).then((res) => (res.success ? res.result : []));
        this.detail.ext1 = '1';
        this.$message({ type: 'success', message: '出库成功!' });
      });
    },
  },
};
</script>
