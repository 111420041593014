var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("TableList", {
        staticStyle: { marginTop: "10px" },
        attrs: {
          data: _vm.orderData,
          formConfig: _vm.config,
          detailData: _vm.detail,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }