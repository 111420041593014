var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-table" },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: { border: "", "show-overflow": "", data: _vm.data },
        },
        [
          _c("vxe-table-column", {
            attrs: { field: "productCode", align: "center", title: "产品编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "productName", align: "center", title: "产品名称" },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "price",
              align: "center",
              title: "直营产品单价(瓶/盒)元",
            },
          }),
          _c("vxe-table-column", {
            attrs: { field: "ext1", align: "center", title: "规格" },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "quantity",
              align: "center",
              title: "数量（瓶/盒）",
            },
          }),
          _c("vxe-table-column", {
            attrs: { field: "amount", align: "center", title: "总金额" },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "div",
            {
              staticStyle: {
                "font-size": "16px",
                height: "20px",
                "line-height": "20px",
                "font-weight": "600",
                margin: "20px 0",
              },
            },
            [_vm._v("订单信息")]
          ),
          _c(
            "el-form",
            {
              ref: "detail",
              attrs: { model: _vm.detail, "label-width": "120px" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单编码" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "订单编码" },
                            model: {
                              value: _vm.detail.orderCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "orderCode", $$v)
                              },
                              expression: "detail.orderCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单创建时间" } },
                        [
                          _c("el-input", {
                            attrs: {
                              disabled: "",
                              placeholder: "订单创建时间",
                            },
                            model: {
                              value: _vm.detail.createDateAll,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "createDateAll", $$v)
                              },
                              expression: "detail.createDateAll",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建人" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "创建人" },
                            model: {
                              value: _vm.detail.createName,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "createName", $$v)
                              },
                              expression: "detail.createName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "创建人岗位" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "创建人岗位" },
                            model: {
                              value: _vm.detail.posName,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "posName", $$v)
                              },
                              expression: "detail.posName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "所属组织" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "", placeholder: "所属组织" },
                            model: {
                              value: _vm.detail.orgName,
                              callback: function ($$v) {
                                _vm.$set(_vm.detail, "orgName", $$v)
                              },
                              expression: "detail.orgName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "订单状态" } },
                        [
                          _vm.detail.enableStatus === "009"
                            ? _c("el-input", {
                                attrs: {
                                  value: "正常",
                                  disabled: "",
                                  placeholder: "订单状态",
                                },
                              })
                            : _vm.detail.enableStatus === "003"
                            ? _c("el-input", {
                                attrs: {
                                  value: "关闭",
                                  disabled: "",
                                  placeholder: "订单状态",
                                },
                              })
                            : _c("el-input", {
                                attrs: {
                                  disabled: "",
                                  placeholder: "订单状态",
                                },
                              }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _vm.config.flag !== "audit" &&
                  _vm.detail.ext1 == "0" &&
                  _vm.detail.orderTypeName !== "退货订单"
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.handleClick },
                        },
                        [_vm._v("确认出库")]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }